import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/(app)/(withSidebar)/[uid]/profile-tab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/goTopButton/goTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/pageWrapper/pageWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/page/profile/profile-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/page/profile/user-activities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/page/profile/user-spots.tsx");
